.header {
    border: 1px solid #ccc;
    box-shadow: 0 8px 4px -7px #888;
    display: flex;
    align-items: center;
    padding: 0px 4% 0px 0%;
    position: fixed;
    width: 100%;
    z-index: 999;
    background: #fff;
    height: 85px;

    .header-logo {
        width: 40%;
        margin-left: -5%;

        img {
            width: 70%;
        }
    }
    .menuButton {
        margin: 0px 3% 0px 1%;
    }

    .header-nav {
        display: flex;
        justify-content: space-evenly;
        margin-left: auto;
    }

    .header_link {
        font-size: 17px;
        font-weight: 500;
        /* margin: 0px 12px; */
        color: #465166;
        text-decoration: none;
        margin-left: 10px;
    }

    .header_option {
        display: flex;
        flex-direction: row;
        font-size: 17px;
        font-weight: 500;
        margin: 0px 12px;
        color: #465166;

        span.header-login {
            margin: 0px 10px;
        }

        .MuiSvgIcon-root {
            margin-top: 2px;
        }
    }

    .ant-dropdown-menu-item {
        padding: 0px 20px 0px;
        line-height: 26px;
    }

    .sidenavbar-header {
        display: none;
    }

}

.ant-dropdown-menu-item>a,
.ant-dropdown-menu-submenu-title>a {
    display: block;
    margin: -5px -12px;
    padding: 5px 20px;
    color: rgba(0, 0, 0, 0.65);
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    font-weight: 500;
    display: flex;
    align-items: center;
}

a span.anticon {
    margin-right: 16px;
    font-size: 18px;
    color: #f07c00;
}

.header-modal {
    width: 59% !important;
    margin-top: 5% !important;
}


////////////responsive header side navbar modal//////////////
.sidenavbar-header {
    position: absolute;
    top: 0;
    z-index: 999;
    right: 11%;

    .ant-layout-sider {
        position: relative;
        min-width: 0;
        background: #fff;
        -webkit-transition: all 0.2s;
        transition: all 0.2s;
        box-shadow: 0px 0px 5px #c9c9c9;
    }


    .ant-layout-sider-children {
        height: 100%;
        margin-top: 7.9px;
        padding-top: 4.1px;
    }

    .ant-layout-sider-zero-width-trigger {
        top: 17px;
        right: -38px;
        z-index: 1;
        width: 42px;
        height: 42px;
        color: #fff;
        font-size: 28px;
        background: linear-gradient(41deg, #EE6F00 0%, #F59500 100%);
    }

    .ant-layout-sider-zero-width-trigger:hover {
        background: linear-gradient(41deg, #EE6F00 0%, #F59500 100%);
    }

    .ant-menu.ant-menu-dark,
    .ant-menu-dark .ant-menu-sub,
    .ant-menu.ant-menu-dark .ant-menu-sub {
        color: #000;
        background: #fff;
    }

    .ant-menu-dark .ant-menu-item,
    .ant-menu-dark .ant-menu-item-group-title,
    .ant-menu-dark .ant-menu-item>a,
    .ant-menu-dark .ant-menu-item>span>a {
        color: #fff;
    }

    .ant-menu.ant-menu-dark .ant-menu-item-selected,
    .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
        background: linear-gradient(41deg, #EE6F00 0%, #F59500 100%) !important;
        color: #fff !important;
    }
}

.userlink{
    display: flex;
    align-items: center;
    .usericon {
        font-size: 29px;
    }
}

@media(max-width:550px) {
    .header {
        .header-nav {
            display: none;
        }

        .header_option {
            color: #373b42;
            font-size: 15px !important;
            margin: 0px !important;

            .MuiSvgIcon-root {
                margin: 6px 8px 0px 0px;
            }
        }

        .sidenavbar-header {
            display: block;
        }
    }
}