// @import './assets/scss/custom.scss';

#root {
  width: 100%;
  overflow: auto;
}

.MuiTable-root {
  width: 100%;
  display: contents;
  border-spacing: 0;
  border-collapse: collapse;
}

.ant-row {
  align-items: center;
}

.muidTable {
  margin-top: 17px;
  width: 100%;
}

.appcontent {
  display: flex;
  width: 100%;
  overflow: hidden;

  .ant-spin-nested-loading {
    width: 100%;
  }

  .ant-layout-sider-children {
    padding-top: 6%;
    position: fixed;
    width: inherit;
  }

  .ant-layout-sider {
    background-color: #fff;
    color: #000c17;
    box-shadow: 3px 3px 6px -3px #000000b3;
  }
}

.ant-menu-sub.ant-menu-inline > .ant-menu-item,
.ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 28px !important;
  line-height: 28px !important;
  list-style-position: inside;
  list-style-type: disc;
}

ul#sub1\$Menu {
  position: absolute;
  // width: 242px;
  font-size: 12px;
}

.appcontent .ant-layout-sider-children {
  // min-width: 247px ;
  // width: 242px ;
  overflow-y: scroll;
  overflow-x: none !important;
}

aside.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-has-trigger {
  min-width: 247px;
  width: 242px;
  box-shadow: none;
}

aside.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed.ant-layout-sider-has-trigger {
  min-width: 80px !important;
  overflow-y: scroll;
}

.select_content {
  margin: 0px 4% 13px 0px;

  .ant-select {
    margin: 7px 0px;
  }

  .ant-input {
    margin: 7px 0px;
  }

  .ant-picker {
    margin: 7px 0px;
    width: 100%;
  }
}

.button_search_content {
  display: flex;
  align-items: baseline;

  .ant-input {
    margin-right: 10px;
  }

  button.btn.btn-primary {
    color: #fff;
    background: #0a0a6b;
    border-color: #0a0a6b;
  }
}

// }
.ant-layout-sider-trigger {
  display: none;
}

.MuiTypography-h6 {
  text-align: left;
}

.ant-layout.ant-layout-has-sider {
  flex-direction: row;
  height: 100vh;
  overflow: auto;

  .ant-menu-inline {
    width: fit-content;
  }
}
.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: 0px solid #f0f0f0;
}
main.right_side_content {
  margin: 8% 16px 0px;
  overflow: initial;

  .form_wapper_content {
    background: #fff;
    padding: 24px;
    margin-bottom: 10px;
  }
}

li.ant-menu-item {
  padding-left: 10px !important;
  font-size: 11px;
}

@media (max-width: 700px) {
  .appcontent .ant-layout-sider-children {
    padding-top: 19%;
  }

  main.right_side_content {
    margin: 23% 16px 0px;
    overflow: initial;

    .right_boxfill_content {
      padding: 12px;
    }

    .select_content {
      margin: 0px;
    }

    .ant-input {
      margin: 10px 0px;
    }

    .button_search_content {
      margin: -14px 0px 10px;
    }
  }

  .muidTable {
    .MUIDataTableToolbar-titleText-20 {
      font-size: 13px;
    }

    .MuiSvgIcon-root {
      font-size: 18px;
    }

    .MUIDataTableBodyCell-stackedCommon-72 {
      font-size: 12px;
    }

    .MUIDataTableBodyCell-stackedCommon-72 {
      width: calc(100%);
      font-size: 10px;
    }
  }
}

.ant-menu-inline-collapsed > .ant-menu-item,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item,
.ant-menu-inline-collapsed
  > .ant-menu-item-group
  > .ant-menu-item-group-list
  > .ant-menu-submenu
  > .ant-menu-submenu-title,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
  left: 15px;
  padding: 0 calc(50% - 16px / 2);
  text-overflow: clip;
}

::-webkit-scrollbar {
  width: 4px;
  height: 7px;
}

::-webkit-scrollbar-track {
  background-color: #fff;
  box-shadow: inset 0 0 5px #f7f7f8;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #017ee9;
  border-radius: 3px;
}

th div {
  font-weight: bold;
}

th,
td {
  border: 1px solid rgba(224, 224, 224, 1);
}

.ant-menu-inline-collapsed > .ant-menu-item,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item,
.ant-menu-inline-collapsed
  > .ant-menu-item-group
  > .ant-menu-item-group-list
  > .ant-menu-submenu
  > .ant-menu-submenu-title,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
  text-align: center;
}
.ant-menu {
  font-size: 11px;
}
