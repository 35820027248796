.loginFrom_section {
    text-align: center !important;
    background: #fff !important;


    .logo_content {
        img {
            width: 20%;
            margin-left: 31px;
        }
    }

    .ant-row.ant-form-item.ant-form-item-with-help.ant-form-item-has-error {
        margin: 20px 15px;
    }

    form {
        .ant-form-item-explain.ant-form-item-explain-error {
            color: #ff4d4f;
            text-align: justify;
            margin: 0px 9px;
        }

        .login_content {
            // width: 40%;

            width: 35%;
            margin: auto;
            box-shadow: 0 2px 5px 0 #00000029, 0 2px 10px 0 #0000001f;
            border-radius: 10px;

            .Profile_Contentusear {
                h4 {
                    font-size: 18px;
                }

                .Usear_profile {
                    background: #8e24aa;
                    padding: 16px;
                    margin: 14px auto;
                    color: #fff;
                    font-size: 32px;
                    border-radius: 50%;
                    width: 17%;
                }

            }


            .ant-form-item-control-input-content {
                padding: 5px;

                span.ant-input-affix-wrapper {
                    padding: 13px;
                    background: #e3e3e3;

                    .ant-input {
                        background: #e3e3e3;
                    }
                }
            }

            .content_checkbox {
                display: flex;

                .forgot_Password {
                    float: right;
                }
            }

        }

        .Login_btn_content {
            .login-form-button {
                position: absolute;
                top: -58%;
                left: 35.5%;
                width: 29%;
                background: #8e24aa;
                height: 46px;
                color: #fff;
                font-size: 20px;
            }
        }
    }

    .MuiGrid-container.MuiGrid-spacing-xs-3 {
        margin: auto !important;
        justify-content: center !important;
        width: 100% !important;

        .MuiCard-root {
            text-align: center !important;




        }
    }
}