// .ant-table-wrapper {
//   padding: 8% 3% 0px;
// }

.sidebar_section {
  // width: 256px;
  padding-top: 5.7%;
  height: 761px !important;
  box-shadow: 3px 3px 6px -3px #000000b3;
  position: sticky;
  top: 0px;
  min-height: 800px;


  ul.ant-menu.ant-menu-dark.ant-menu-root.ant-menu-inline {
    height: 93%;
    background: #ffff;
    color: #001529;
    width: 225px;
    overflow: auto;
    // box-shadow: 3px 3px 6px -3px #000000b3;
    height: 100%;
    z-index: 1000;
    overflow-y: auto;
    transition: all 0.2s ease;
    -webkit-transition: all 0.2s ease;
  }

  .ant-menu.ant-menu-dark,
  .ant-menu-dark .ant-menu-sub,
  .ant-menu.ant-menu-dark .ant-menu-sub {
    background: #ffff;
    color: #001529;
  }

  .ant-menu-dark .ant-menu-item,
  .ant-menu-dark .ant-menu-item-group-title,
  .ant-menu-dark .ant-menu-item>a,
  .ant-menu-dark .ant-menu-item>span>a {
    color: #001529;
  }

  .ant-menu-dark .ant-menu-item:hover,
  .ant-menu-dark .ant-menu-item-active,
  .ant-menu-dark .ant-menu-submenu-active,
  .ant-menu-dark .ant-menu-submenu-open,
  .ant-menu-dark .ant-menu-submenu-selected,
  .ant-menu-dark .ant-menu-submenu-title:hover {
    color: #fff;
    background-color: #0a0a6b;
  }

  .ant-menu-dark .ant-menu-item:hover>a,
  .ant-menu-dark .ant-menu-item-active>a,
  .ant-menu-dark .ant-menu-submenu-active>a,
  .ant-menu-dark .ant-menu-submenu-open>a,
  .ant-menu-dark .ant-menu-submenu-selected>a,
  .ant-menu-dark .ant-menu-submenu-title:hover>a,
  .ant-menu-dark .ant-menu-item:hover>span>a,
  .ant-menu-dark .ant-menu-item-active>span>a,
  .ant-menu-dark .ant-menu-submenu-active>span>a,
  .ant-menu-dark .ant-menu-submenu-open>span>a,
  .ant-menu-dark .ant-menu-submenu-selected>span>a,
  .ant-menu-dark .ant-menu-submenu-title:hover>span>a {
    color: #fff;
    background-color: #0a0a6b;
    
  }
}
.fliter_content{
  margin: 0px 0px 13px;
  .Select_second_option{
    margin-left: 30px;
    margin-right: 0px;
  }
  .date_picker{
    text-align: right;
    .ant-picker-range {
      width: 80%;
    }
  }
  .ant-input-number{
    margin-left: inherit;
    .ant-input-number-handler-wrap{
      opacity: 1 !important;
    }
    .ant-input-number-handler-wrap:hover .ant-input-number-handler {
      height: 50%;
  }
  }
  .Select_comparision_option{
    margin-left: auto;
  }
}
.ant-card.ant-card-bordered.card_box{
  height: 238px;
  margin: 10px 0px;
  box-shadow: 0 14px 26px rgba(0,0,0,0.04);
  transition: all 0.3s ease-out;
}
.ant-card.ant-card-bordered.card_box:hover{
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  transform: translateY(-5px) scale(1.005) 
}