.row-heading{
    background-color: #0a0a6b;
    padding: 5px;
    margin: 12px;
}

.row-heading h3{
    color: #fff;
}

.row-headingmpt{
    padding: 5px;
    margin: 12px;
}